<template>
    <div class="add_dealings">
        <div class="title">
            <i>+</i>
            添加往来
        </div>
        <el-form label-width="90px" label-position="left" size="small">
            <el-form-item label="往来时间">
                <el-date-picker
                    style="width:100%"
                    v-model="form.intercourseTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="往来标题">
                <el-input v-model="form.intercourseTitle" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" required>
                <el-select v-model="form.contactWay" style="width:100%" placeholder="请选择">
                    <el-option label="电话" :value="1"></el-option>
                    <el-option label="微信" :value="2"></el-option>
                    <el-option label="邮箱" :value="3"></el-option>
                    <el-option label="QQ" :value="4"></el-option>
                    <el-option label="短信" :value="5"></el-option>
                    <el-option label="拜访" :value="6"></el-option>
                    <el-option label="官网表单" :value="8"></el-option>
                    <el-option label="座机" :value="9"></el-option>
                    <el-option label="其他" :value="7"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="往来内容" required>
                <el-input
                    type="textarea"
                    rows="6"
                    placeholder="请输入"
                    v-model="form.intercourseContent"
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="bottom_btn">
            <el-button size="mini" round @click="onClose">取消</el-button>
            <el-button
                type="primary"
                size="mini"
                round
                @click="dealingAdd"
                style="background:#2370eb;border-color:#2370eb"
                >保存</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: ['data'],
    data() {
        return {
            form: {
                intercourseTime: this.$handTime.getFormatDate(),
                intercourseTitle: '',
                contactWay: 1,
                intercourseContent: '',
            },
        };
    },
    components: {},
    created() {},
    methods: {
        dealingAdd() {
            if (this.form.contactWay == '') {
                this.$message.error('请选择联系方式');
                return;
            }
            if (this.form.intercourseContent == '') {
                this.$message.error('请输入往来内容');
                return;
            }
            let data = {
                param: {
                    ...this.form,
                },
            };
            this.$emit('dealingAdd', data);
            this.form = {
                intercourseTime: '',
                intercourseTitle: '',
                contactWay: '',
                intercourseContent: '',
            };
        },
        onClose() {
            this.form = {
                intercourseTime: '',
                intercourseTitle: '',
                contactWay: '',
                intercourseContent: '',
            };
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.add_dealings {
    display: flex;
    height: 100%;
    flex-direction: column;
}
.title {
    height: 50px;
    line-height: 50px;
    padding-left: 28px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #eeeeee;
}
.title i {
    color: #fff;
    background-color: #2370eb;
    border-radius: 50%;
    font-style: normal;
    display: inline-block;
    width: 12px;
    height: 12px;
    display: flex;
    line-height: 0.79;
    justify-content: center;
    margin-right: 10px;
}
.add_dealings .el-form {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    flex: 1;
}
.add_dealings >>> .el-textarea__inner {
    resize: none;
}
.add_dealings .bottom_btn {
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.bottom_btn button {
    width: 80px;
    height: 30px;
}
</style>
