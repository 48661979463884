<template>
    <div class="dealings">
        <div class="top_title">
            <span><i>往来</i></span>
            <div class="center_info">
                <p>负责人：{{ data.followAdminName ? data.followAdminName : '- -' }}</p>
                <p>跟进时间：{{ data.updateTime }}</p>
            </div>
            <span @click="setStatus" v-if="data">
                <!-- <i
                    :class="data.mark == 0 ? 'iconfont icon-star' : 'iconfont icon-shoucang'"
                    :style="{
                        color: data.mark == 0 ? '#c9c9c9' : '#ffa802',
                    }"
                ></i>
                {{ data.mark == 0 ? '设为星标' : '取消星标' }} -->
            </span>
        </div>
        <div class="info">
            <div class="left_box">
                <Add @close="close" v-if="data.id" @dealingAdd="dealingAdd" :data="data"></Add>
            </div>
            <div class="right_box">
                <div class="title">
                    <i class="el-icon-s-order"></i>
                    往来记录
                </div>
                <History
                    v-if="historyData"
                    @close="close"
                    :historyData="historyData"
                    :isLibrary="isLibrary"
                    @addExplain="addExplain"
                    ref="dealingHistory"
                ></History>
            </div>
        </div>
    </div>
</template>

<script>
import { setStatus, dealingDesAdd } from '@/api/database/database';
import Add from './components/add.vue';
import History from './components/history.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            tag: true,
            data: {},
            historyData: [],
            isLibrary: true,
        };
    },
    components: {
        Add,
        History,
    },
    methods: {
        setStatus() {
            let mark = this.data.mark == 0 ? 1 : 0;
            let data = {
                param: {
                    id: this.data.id,
                    mark: mark,
                },
            };
            setStatus(data).then((res) => {
                if (res.code == 200 && res.data) {
                    this.$message.success('操作成功');
                    this.data.mark = this.data.mark == 0 ? 1 : 0;
                    this.data = JSON.parse(JSON.stringify(this.data));
                }
            });
        },
        dealingAdd(data) {
            this.$emit('dealingAdd', data);
        },
        close() {
            this.$emit('close');
        },

        addExplain(data) {
            this.$emit('addExplain', data);
        },
    },
};
</script>

<style scoped>
.dealings {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.dealings .top_title {
    border-bottom: 1px dashed #eeeeee;
    display: flex;
    font-size: 16px;
    padding: 18px 15px 18px 35px;
    width: 100%;
    box-sizing: border-box;
}
.top_title .center_info {
    flex: 1;
    margin-left: 10px;
}
.center_info p:first-child {
    margin-bottom: 8px;
}
.top_title span:first-child {
    color: #fff;
    width: 20px;
    height: 20px;
    background: #2370eb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.top_title span:first-child i {
    font-size: 16px;
    display: inline-block;
    font-style: normal;
    transform: scale(0.5, 0.5);
    white-space: nowrap;
    line-height: 1.2;
}
.top_title span:last-child {
    cursor: pointer;
}
.dealings .info {
    display: flex;
    flex: 1;
    justify-content: space-between;
    background-color: #f0f2f4;
    height: calc(100% - 87px);
}
.info > div {
    width: 49.5%;
    background-color: #fff;
    height: 100%;
    overflow: hidden;
}
</style>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.right_box {
    .title {
        height: 50px;
        line-height: 50px;
        padding-left: 28px;
        font-size: 14px;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed #eeeeee;
        i {
            color: #feb516;
            margin-right: 10px;
        }
    }
}
</style>
