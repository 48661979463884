<template>
    <div class="history">
        <div class="history_list">
            <div class="item" v-for="item in historyData" :key="item.id">
                <div class="add_explain">
                    <p
                        class="first_p"
                        @click="showDialog(item.id)"
                        v-if="!disabled"
                    >
                        添加说明
                    </p>
                    <p
                        class="last_p"
                        v-if="item.customerId && item.customerId != 0"
                    >
                        来源：客户往来
                    </p>
                    <p
                        class="last_p"
                        v-if="item.customerId && item.customerId == 0"
                    >
                        来源：{{
                            $tableDataHandle.productCategory(item.productClue)
                        }}
                    </p>
                </div>
                <p class="cicle">
                    <span>往来时间</span
                    ><span>{{ item.intercourseTime || '- -' }}</span>
                </p>
                <p>
                    <span>往来标题</span
                    ><span>{{ item.intercourseTitle || '- -' }}</span>
                </p>
                <p>
                    <span>跟进人</span
                    ><span>{{ item.adminName || '- -' }}</span>
                </p>
                <p>
                    <span>联系方式</span>
                    <span>{{
                        item.contactWay == 1
                            ? '电话'
                            : item.contactWay == 2
                            ? '微信'
                            : item.contactWay == 3
                            ? '邮箱'
                            : item.contactWay == 4
                            ? 'QQ'
                            : item.contactWay == 5
                            ? '短信'
                            : item.contactWay == 6
                            ? '拜访'
                            : item.contactWay == 7
                            ? '其他'
                            : item.contactWay == 8
                            ? '官网表单'
                            : item.contactWay == 9
                            ? '座机'
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span>往来内容</span
                    ><span
                        style="
                            background: #f0f4ff;
                            padding: 5px;
                            border-radius: 4px;
                            margin-left: -5px;
                            margin-top: -5px;
                        "
                        >{{ item.intercourseContent || '- -' }}</span
                    >
                </p>
                <p v-for="items in item.describeList">
                    <span>往来说明</span
                    ><span>{{ items.describeContent || '- -' }}</span>
                </p>
            </div>
            <div class="empty" v-if="historyData.length == 0">
                <img src="@/assets/zanwushuju.png" alt="" />
                <span style="display: block">暂无数据～</span>
            </div>
        </div>

        <!-- 往来添加说明 -->
        <el-dialog
            :visible.sync="dialog"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="dialog"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    添加说明
                </div>
            </template>
            <el-form label-width="75px" label-position="left" size="small">
                <el-form-item label="说明内容" required>
                    <el-input
                        type="textarea"
                        rows="8"
                        v-model="describeContent"
                        placeholder="请输入说明内容"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog_footer">
                <el-button @click="dialog = false" round size="mini"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addExplain" round size="mini"
                    >确 定</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: '',
    props: ['historyData', 'isLibrary', 'disabled'],
    data() {
        return {
            describeContent: '',
            dialog: false,
            describeId: '',
        };
    },
    components: {},
    created() {},
    mounted() {},
    methods: {
        showDialog(id) {
            this.dialog = true;
            this.describeId = id;
        },
        addExplain() {
            if (!this.describeContent) {
                return this.$message.error('请输入说明内容');
            }
            let data = {
                param: {
                    intercourseId: this.describeId,
                    describeContent: this.describeContent,
                },
            };
            this.$emit('addExplain', data);
            this.handleClose();
        },
        handleClose() {
            this.describeContent = '';
            this.dialog = false;
        },
    },
};
</script>

<style scoped>
.history {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
}
.history_list {
    flex: 1;
    overflow-y: auto;
    font-size: 14px;
    color: #333;
}
.history_list .item {
    padding: 15px 40px 0;
    border-bottom: 1px dashed #eeeeee;
    position: relative;
}
.history_list .item:last-child {
    border-bottom: none;
}
.history_list .item p span:first-child {
    color: #666;
    margin-right: 20px;
    display: block;
    width: 60px;
    white-space: nowrap;
}
.history_list .item p {
    margin-bottom: 15px;
    display: flex;
    position: relative;
}
.history_list .item .cicle:before {
    content: '';
    position: absolute;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background: #2370eb;
    border-radius: 50%;
}
.history_list .item p span:last-child {
    flex: 1;
    word-break: break-all;
}
.add_explain {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 12px;
    text-align: right;
}
.add_explain .last_p {
    color: #2370eb;
    padding: 5px 10px;
    background: rgba(35, 112, 235, 0.1);
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
}
.add_explain .first_p {
    display: inline-block !important;
    color: #ffa802;
    position: relative;
    z-index: 2;
    padding: 5px 10px;
    border: 1px solid #ffa802;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
}
.dialog .el-form {
    width: 65%;
    margin: 20px auto 150px;
}
.dialog >>> .el-textarea__inner {
    resize: none;
}
.empty {
    text-align: center;
    padding-top: 50px;
}
</style>
