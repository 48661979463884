import http from '@/utils/http.js'

// 资料库列表
export function libraryList(data) {
    return http({
        url: "/jasoboss/library/list",
        method: "post",
        data
    })
}
// 单个资料库
export function libraryGet(data) {
    return http({
        url: "/jasoboss/library/get",
        method: "post",
        data
    })
}
// 资料库添加
export function libraryAdd(data) {
    return http({
        url: "/jasoboss/library/add",
        method: "post",
        data
    })
}
// 资料库修改
export function libraryUpdate(data) {
    return http({
        url: "/jasoboss/library/update",
        method: "post",
        data
    })
}
// 资料库导入
export function libraryImport(data) {
    return http({
        url: "/jasoboss/library/import",
        method: "post",
        data
    })
}
// 资料库清洗
export function libraryClean(data) {
    return http({
        url: "/jasoboss/library/clean",
        method: "post",
        data
    })
}
// 资料库修改状态
export function setStatus(data) {
    return http({
        url: "/jasoboss/library/set/mark/status",
        method: "post",
        data
    })
}
// 往来列表
export function dealingList(data) {
    return http({
        url: "/jasoboss/library/intercourse/list",
        method: "post",
        data
    })
}
// 往来添加
export function dealingAdd(data) {
    return http({
        url: "/jasoboss/library/intercourse/add",
        method: "post",
        data
    })
}
// 往来添加说明
export function dealingDesAdd(data) {
    return http({
        url: "/jasoboss/library/intercourse/describe/add",
        method: "post",
        data
    })
}
// 资料库 客户
export function relationCustomer(data) {
    return http({
        url: "/jasoboss/customer/library/relation",
        method: "post",
        data
    })
}
// 资料库 分配资料
export function libraryAllot(data) {
    return http({
        url: "/jasoboss/library/allot/follow",
        method: "post",
        data
    })
}
// 客户添加域名
export function customerAddDomain(data) {
    return http({
        url: "/jasoboss/customer/domain/add",
        method: "post",
        data
    })
}
// 批量删除
export function libraryBatchDel(data) {
    return http({
        url: "/jasoboss/library/delete",
        method: "post",
        data
    })
}
